.partner-card {
    width: 180px;
    height: 254px;
    background-image: linear-gradient(163deg, #58ddf8 0%, #87c4f0 100%);
    border-radius: 20px;
    transition: all .3s;
    margin: 2%;
   }
   
   .card2 {
    width: 180px;
    height: 254px;
    background-color: #ffffff;
    border-radius: 10px;
    transition: all .2s;
    color:rgb(44, 44, 44);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
   }
   
   .card2:hover {
    transform: scale(0.98);
    border-radius: 20px;
   }
   
   .partner-card:hover {
    box-shadow: 0px 0px 30px 1px rgba(99, 247, 252, 0.3);
   }

   @media (max-width: 600px) {
    .partner-card{
        margin-right: 0px;
        margin-bottom: 10px;
        margin-top: 5%;
    }
}