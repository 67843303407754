@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
.mainDiv{
   
}
.log-reg{
    padding: 10px;
background-color: #fff;
color: #333;
font-family: 'Inter', sans-serif;
display: flex;
justify-content:'center';
align-items:center;
font-family: 'Raleway', sans-serif;
}

.log-reg div {
    font-family: 'Raleway', sans-serif;

}
.h1{
    font-family: 'Raleway', sans-serif;

}
.input {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  font-weight: 500;
  font-size: .8vw;
  color: #fff;
  background-color: rgb(28,28,30);
  box-shadow: 0 0 .4vw rgba(0,0,0,0.5), 0 0 0 .15vw transparent;
  border-radius: 0.4vw;
  border: none;
  outline: none;
  padding: 0.4vw;
  max-width: 300px;
  transition: .4s;
}

.input:hover {
  box-shadow: 0 0 0 .15vw rgba(135, 207, 235, 0.186);
}

.input:focus {
  box-shadow: 0 0 0 .15vw skyblue;
}
.button {
    background-color:white; 
    width:50%;
    border-radius:10px;
    color:black;
    margin: 10px;
    box-shadow: none;
}
.button:hover{
    background-color: rgb(134, 218, 250);
    color: white;
}
.log-reg .mdb-btn {
    width: 80%;
  }
@media (max-width: 600px) {
    .login-form {
      width: 100%;
    }
    .login-form .ant-form-item input {
      width: 100%;
    }
    .login-form .ant-form-item-control-input-content input {
      font-size: 16px;
    }
    .mainDiv{
        width: 100%;
    }
    .button{
        width: 90%;
    }
  }
  