/* styles.css */
.announcement-banner {
    color: #333; /* Dark text color */
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    flex-wrap: nowrap;
    background-color: #fff;
}


.announcement-banner p {
    margin: 0;
    display: flex;
    flex: 1;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.announcement-banner .info-button {
    background-color: #007BFF; /* A typical button color, you can adjust as needed */
    color: white;
    padding: 5px 15px;
    margin-right: 10px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
}

.announcement-banner .info-button:hover {
    background-color: #0056b3; /* Darkened for hover effect */
}

.announcement-banner .close-button {
    background: none;
    border: none;
    color: #666; /* Slightly lighter than main text for less prominence */
    font-size: 1.2em;
    cursor: pointer;
}
.btn1 {
    outline: 0;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    background: #9370e4;
    min-width: 200px;
    border: 0;
    border-radius: 4px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, .1);
    box-sizing: border-box;
    padding: 16px 20px;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    overflow: hidden;
    cursor: pointer;
    margin-right: 5px;
  }
  
  .btn1:hover {
    opacity: .95;
  }
  
  .btn1 .animation {
    border-radius: 100%;
    animation: ripple 0.6s linear infinite;
  }
  
  @keyframes ripple {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1), 0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 40px rgba(255, 255, 255, 0.1), 0 0 0 60px rgba(255, 255, 255, 0.1);
    }
  
    100% {
      box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 40px rgba(255, 255, 255, 0.1), 0 0 0 60px rgba(255, 255, 255, 0.1), 0 0 0 80px rgba(255, 255, 255, 0);
    }
  }
  @media (max-width: 768px) {
    .btn1{
        margin-right: 0px;
    }
    .announcement-banner {
        flex-direction: column;
        align-items: flex-start;
        padding: 5px 10px;
        font-size: 0.9em;
    }

    .announcement-banner p {
        margin-bottom: 10px;
    }

    .announcement-banner > div {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .announcement-banner .info-button, .btn1 {
        margin-right: 0;
        margin-top: 10px;
        width: 100%;
        display: block;
    }

    .announcement-banner .close-button {
        font-size: 1em;
        margin-left: 10px;
    }
}

