.cookie-policy-container {
    width: 60%;
    text-align: left;
    margin: auto;
    margin-top: 10px;
}

@media only screen and (max-width: 768px) {
    .cookie-policy-container {
        width: 95%;
    }
}