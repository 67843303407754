.respklasa {
    flex-direction: column-reverse;
}

@media only screen and (min-width: 600px) {
    .respklasa {
        justify-content: space-between;
        flex-direction: row;
        height: 70vh;
    }
}