.interest-form {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 300px;
    margin: 20px auto;
    text-align: center;
}

.interest-form label {
    display: block;
    margin-bottom: 10px;
}

.interest-form input,
.interest-form select,
.interest-form button {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}