.RoleCrud {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .RoleCrud-input {
    width: 200px;
    height: 40px;
    margin: 10px 0;
    padding: 0 10px;
}