.log-reg {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    font-family: 'Raleway', sans-serif;
  }
  .div {
    display: flex;
    justify-content:center;
    align-items:center;
  }
  .mdb-card{

  }
  .log-reg .mdb-card {
    border-radius: 25px;
    font-family: sans-serif;
    background-color: #f5f5f5;
    max-width: 900px;
    justify-content: center;
    align-items: center;
  }
  
  .log-reg .text-center.h1.fw-bold.mb-5.mx-1.mx-md-4.mt-4 {
    font-family: sans-serif;
    font-size: 2em;
    color: gray;
  }
  
  .log-reg .d-flex.flex-row.align-items-center.mb-4 {
    margin-bottom: 20px;
  }
  
  .log-reg .d-flex.flex-row.align-items-center.mb-4 .mdb-input {
    width: 100%;
  }
  
  .log-reg .mdb-checkbox {
    margin-bottom: 20px;
  }
  
  .log-reg .mdb-btn {
    background-color:white; 
    width: 80%;
  }
  
  @media (max-width: 600px) {
    .log-reg .text-center.h1.fw-bold.mb-5.mx-1.mx-md-4.mt-4 {
      font-size: 1.5em;
    }
    .log-reg {
        width: 100%;
    }
    .div {
        width: 100%;
    }
    .log-reg .mdb-card{
        width: 100;
    }
    .mdb-card{
        width: 100vw;
    }
  }
  