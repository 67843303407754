.code-snippet {
    background-color: #222;
    border-radius: 4px;
    font-family: 'Fira Code', monospace;
    font-size: 14px;
    line-height: 1.5;
    overflow-x: auto;
    padding: 20px;
    position: relative;
  }
  
  .code-snippet.light {
    background-color: #f5f5f5;
    color: #ffffff;
  }
  
  .code-snippet .language {
    color: #8ed2f4;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
  
  .code-snippet .code {
    color: #fff;
    padding-right: 20px;
    
  }
  
  .code-snippet .code .keyword {
    color: #6200ee;
    font-weight: 600;
  }
  
  .code-snippet .code .string {
    color: #ad1457;
  }
  
  .code-snippet .code .comments {
    color: #757575;
    font-style: italic;
  }
  
  /* Typing effect */
  .code-snippet .typing {
    background-color: transparent;
    border-right: 2px solid #6200ee;
    height: 1.5em;
    width: 10px;
    animation: typing 1s linear infinite;
    right: 0;
    top: 0;
  }
  
  @keyframes typing {
    from {
      width: 10px;
    }
    to {
      width: 100%;
    }
  }
  
  /* Responsive design */
  @media (max-width: 600px) {
    .code-snippet {
      font-size: 12px;
    }
  }
  