@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
/* Pricing.css */

  
  .title {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .subtitle {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .button-container {
    display: flex;
    justify-content: center;
  }
  
  .pacage-button {
    background-color: #4CAF50;
    color: white;
    padding: 1rem 2rem;
    margin: 0 1rem;
    font-size: 1.2rem;
    cursor: pointer;
  }
  
  .pacages-title {
    font-size: 2rem;
    font-weight: bold;
    margin: 2rem 0;
    display: block;
  }
  
  .pacages-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .pacage {
    background-color: #f2f2f2;
    padding: 1rem;
    margin: 1rem;
    width: 20rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    transition: background-color 0.5s ease;

  }
  
  .pacage-countries {
    font-size: 1.4rem;
    margin-bottom: 1rem;
    font-weight: bold;
  }
  
  .pacage-price {
    font-size: 1.3rem;
  }
  .pacage:hover {
    background-color: #e6e6e6;
  }

  @media (max-width: 912px) {
    .button-container {
      flex-direction: column;
    }
  }
  