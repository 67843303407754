.contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    max-width: 800px;
    margin: 0 auto;
    padding: 40px;
    border-radius: 15px;
    margin-top: 10vh;
  }
  
  .contact-form h1 {
    font-size: 2em;
    margin-bottom: 10px;
    text-align: center;
    color: 'gray';
  }
  
  .contact-form .ant-form-item {
    margin-bottom: 20px;
  }
  
  .contact-form .ant-form-item input {
    height: 40px;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    padding: 0 20px;
  }