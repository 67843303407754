@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

.navbar {
  display: flex;
  align-items: center;
  color: rgb(0, 0, 0);
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  min-height: 70px;
  position: 'fixed';
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  
}

.navbar .logo {
  font-size: 24px;
  font-weight: 600;
  margin: 0 40px;
  width: 150px;
  height: 22px;
}

.navbar .menu {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.navbar .menu a {
  color: rgb(232, 232, 232);
  margin: 0 20px;
  padding-top: 2px;
  padding-bottom: 2px;
  text-decoration: none;
}

.navbar .menu a:hover {
  color: #6200ee;
}

/* Responsive design */
@media (max-width: 912px) {
  .navbar {
    font-size: 14px;
  }
  .navbar .logo {
    font-size: 20px;
    margin: 0 30px;
  }
  .navbar .menu a {
    margin: 0 10px;
  }
}
