.language-buttons {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    max-width: 98vw;
  }
  .cont{
    max-width: 90vw;
  }
  .language-button {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #333;
    background-color: #f5f5f5;
    border: none;
    border-radius: 4px;
    padding: 8px 12px;
    margin-right: 8px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .language-button:hover,
  .language-button:focus {
    background-color: #e5e5e5;
  }
  
  .language-button:disabled {
    background-color: #f5f5f5;
    color: #999;
    cursor: not-allowed;
  }
  
  @media (max-width: 480px) {
    .language-buttons {
      flex-wrap: wrap;
    }
    .language-button {
      font-size: 12px;
      padding: 6px 8px;
      margin-bottom: 8px;
    }
  }
  