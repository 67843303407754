@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
.cardLanding {
  width: 90%;
  height: 254px;
  border-radius: 10px;
  background: #e0e0e0;
  box-shadow: 15px 15px 30px #bebebe,
             -15px -15px 30px #ffffff;
  display: flex;
  justify-content: space-evenly;
  align-items:center;
  flex-direction: column;
  margin-top: 7%;
  padding : 10px;
  font-family: 'Inter', sans-serif;
  color: 'gray';
  font-size: 1em;

}

.partners-div{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding : 10px;
  margin-top: 7%;
  }

button {
  padding: 15px 25px;
  border: unset;
  border-radius: 10px;
  color: #212121;
  z-index: 1;
  background: #e8e8e8;
  position: relative;
  font-size: 17px;
  transition: all 250ms;
  overflow: hidden;
 }
 

 

.first_div {
display: flex;
flex-direction: row;
justify-content: center;
width: 90vw;
}


.landing-page {
max-width: 80%;
margin: 0 auto;
padding: 30px;
background-color: #f5f5f5;
color: #333;
font-family: 'Inter', sans-serif;
display: flex;
align-items: center;
flex-direction: column;

}

.landing-page h1 {
font-size: 2.25em;
font-weight: 600;
margin-bottom: 50px;
margin-top: 10%;
}

.landing-page p {
font-size: 1.125em;
line-height: 1.5;
margin-bottom: 20px;
}

.landing-page h2 {
font-size: 1.5em;
font-weight: 500;
margin-bottom: 10px;
}

.landing-page ul {
margin: 0;
padding: 0px;
display: flex;
flex-direction: row;
padding-top: 10px;
}

.landing-page li {
font-size: 0.8em;
line-height: 1.5;
margin-bottom: 10px;
display: flex;
align-items: center;
background-color: #010f15;
color: #fff;
border-radius: 10px;
padding: 15px;
transition: all 0.3s;
font-family: 'Inter', sans-serif;
 border-radius: 10px;
 background: #e0e0e0;


}

.landing-page li::before {
content: '';
border-radius: 20%;
background-color: #333;
position: absolute;
}

.landing-page li:hover {
  background-color: #8ed2f4;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

.landing-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
  color: #333;
  font-family: 'Inter', sans-serif;
}

.landing-page h1 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 15%;
}

.landing-page p {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.landing-page h2 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
}

.landing-page ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 7%;
    
  }
  
  .landing-page li {
    font-size: 0.9em;
    margin-bottom: 10px;
    margin-right: 20px;
    position: relative;
    display: flex;
    align-items: center;
    color: rgb(47, 47, 47);
    border-radius: 5px;
    transition: all 0.3s;
    font-family: 'Inter', sans-serif;
  }
  
  .landing-page li::before {
    content: '';
    border-radius: 20%;
    background-color: #333;
    position: absolute;
    left: -20px;
  }
  

.landing-page li:hover {
  background-color: #8ed2f4;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: #fff;
  margin-bottom: 20px;

}
  
  /* Responsive design */
  @media (max-width: 912px) {
    .landing-page {
      padding: 20px;
    }
    .landing-page h1 {
      font-size: 28px;
    }
    .landing-page p {
      font-size: 16px;
    }
    .landing-page h2 {
      font-size: 20px;
    }
    .landing-page li {
      font-size: 14px;
      margin-right: 0px;
      width : 90%;
      height: 10%;
    }
    .landing-page ul {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .first_div {
      flex-direction: column;
    }
    .innerDiv1{
      margin-right: 0px;
      margin-bottom: 5%;
    }
    .cardLanding{
      width: 90vw;
      font-size: 0.7em;
    }
    .partners-div{
      flex-direction: column;
    }

  }
  