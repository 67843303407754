.cookie-consent-container {
    position: fixed;
    background-color: white;
    border-top: 1px solid gray;
    z-index: 9999;
    flex-direction: row;
    bottom: 0px;
    left: 0px;
    right: 0px;
    justify-content: space-around;
    align-items: center;
}

.cookies-description-section {
    text-align: left;
    max-width: 50vw;
    margin-top: 10px;
    margin-bottom: 10px;
}

.cookies-selection-section {
    font-size: 14px;

}

.cookies-x {
    align-self: flex-start;
    margin-top: 10px;
}

.cookies-x-phone {
    display: none;
}

@media only screen and (max-width: 768px) {

    .cookies-x {
        display: none;
    }

    .cookies-x-phone {
        display: flex;
        flex-direction: row;
        justify-content: end;
        width: 100%;
        margin-top: 12px;
        margin-right: 24px;
    }

    .cookie-consent-container {
        flex-direction: column;

    }

    .cookies-description-section {
        font-size: 14px;
    }
}